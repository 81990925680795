import React from "react";

const NamePopOver = ({ names }) => {
  return (
    <div className="absolute rounded-md z-40 bg-white w-32 max-h-32 text-center overflow-y-auto text-black/[0.7] mt-12 border border-gray-400 p-2">
      {names.length > 0 ? (
        names.map(n => {
          return <p key={n} className="text-sm">{n}</p>;
        })
      ) : (
        <p className="text-xs">stinky 💩</p>
      )}
    </div>
  )
};

export default NamePopOver;