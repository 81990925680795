import React, { useState, useRef } from "react";
import { FadeLoader } from "react-spinners";
import { FaCheck } from "react-icons/fa";
import { uploadPicture } from "../api/storage";
import { addPNM } from "../api/pnm";

const Submit = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("@stanford.edu");
  const [photo, setPhoto] = useState();
  const [phone, setPhone] = useState("");
  const [dorm, setDorm] = useState("");
  const [roomNum, setRoomNum] = useState("");
  const [year, setYear] = useState(0);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const imageRef = useRef(null);

  const checkValid = () => {
    return name.length > 0 && email.length > "@stanford.edu".length && email.includes("@stanford.edu") && !!photo;
  };

  const onSubmit = async () => {
    setLoading(true);
    const { url, id } = await uploadPicture(photo, name);
    await addPNM(name, email, phone, year, url, id, dorm, roomNum);
    window.location.reload();
  };

  return (
    <div className="h-screen w-screen bg-hunter-green/[0.8] flex justify-center items-center">
      <div className="space-y-6">
        <p className="text-5xl font-bold text-white">Phi Kappa Psi Rush 2024</p>
        {loading ? (
          <div className="flex justify-center items-center">
            <FadeLoader color="white" />
          </div>
        ) : (
          <>
            <div>
              <p className="font-semibold text-white">Name</p>
              <input type="text" value={name} onChange={e => setName(e.target.value)} className="w-full rounded-md" placeholder="John Doe" />
            </div>
            <div>
              <p className="font-semibold text-white">Stanford Email</p>
              <input type="email" value={email} onChange={e => setEmail(e.target.value)} className="w-full rounded-md" />
            </div>
            <div>
              <p className="font-semibold text-white">Phone Number</p>
              <input type="text" value={phone} onChange={e => setPhone(e.target.value)} className="w-full rounded-md" placeholder="(111) 222-3333" />
            </div>
            <div>
              <p className="font-semibold text-white">Year</p>
              <select value={year} onChange={e => setYear(e.target.value)} className="form-select rounded-md w-full">
                <option value={0}>Freshman</option>
                <option value={1}>Sophomore</option>
                <option value={2}>Other</option>
              </select>
            </div>
            <div className="flex space-x-4">
              <div className="w-2/3">
                <p className="font-semibold text-white">Dorm</p>
                <input type="text" value={dorm} onChange={e => setDorm(e.target.value)} className="w-full rounded-md" placeholder="Phi Kappa Psi" />
              </div>
              <div className="w-1/3">
                <p className="font-semibold text-white">Room Num.</p>
                <input type="text" value={roomNum} onChange={e => setRoomNum(e.target.value)} className="w-full rounded-md" placeholder="208" />
              </div>
            </div>
            <div>
              <p className="font-semibold text-white">Photo</p>
              {!!!photo ? (
                <div
                  className="w-1/4 bg-cardinal-red/[0.5] hover:cursor-pointer hover:bg-cardinal-red/[0.7] text-center text-white rounded-md p-2"
                  onClick={() => imageRef.current.click()}
                >
                  Upload
                </div>
              ) : (
                <FaCheck className="text-white font-semibold text-center" />
              )}
              <input className="hidden" ref={imageRef} type="file" accept="image/*" onChange={e => setPhoto(e.target.files[0])} />
            </div>
            <div className="flex justify-center items-center">
              <div
                className="w-1/4 bg-cardinal-red/[0.5] hover:cursor-pointer hover:bg-cardinal-red/[0.7] text-center text-white rounded-md p-2"
                onClick={() => {
                  if (checkValid()) onSubmit();
                  else setError(true);
                }}
              >
                Submit
              </div>
            </div>
            {error && <p className="text-cardinal-red/[0.7] font-bold">Check your answers and try again.</p>}
          </>
        )}
      </div>
    </div>
  );
};

export default Submit;