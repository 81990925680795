import React, { useState, useEffect } from "react";
import { HiXMark } from "react-icons/hi2";
import { FaRegThumbsUp, FaRegThumbsDown, FaRegStar, FaThumbsUp, FaThumbsDown, FaStar } from "react-icons/fa";
import { useAuth } from "../contexts/AuthContext";
import Comment from "./Comment";
import { addComment, getComments, star, unstar, like, unlike, dislike, undislike, deleteComment, adminDeletePNM } from "../api/pnm";
import { convertUIDsToNames, isAdmin } from "../api/actives";
import NamePopOver from "./NamePopOver";
import Button from "./Button";
import { deletePicture } from "../api/storage";

const ActionModal = ({ pnm, closeModal }) => {
  const { user, username } = useAuth();
  const [stars, setStars] = useState(pnm.stars);
  const [likes, setLikes] = useState(pnm.likes);
  const [dislikes, setDislikes] = useState(pnm.dislikes);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [starNames, setStarNames] = useState([]);
  const [likeNames, setLikeNames] = useState([]);
  const [dislikeNames, setDislikeNames] = useState([]);
  const [starHidden, setStarHidden] = useState(true);
  const [likeHidden, setLikeHidden] = useState(true);
  const [dislikeHidden, setDislikeHidden] = useState(true);
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = getComments(pnm.id, setComments);
    isAdmin(user.uid).then(res => setAdmin(res));

    return () => unsubscribe();
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    convertUIDsToNames(stars).then(names => setStarNames(names));
    convertUIDsToNames(likes).then(names => setLikeNames(names));
    convertUIDsToNames(dislikes).then(names => setDislikeNames(names));
  }, [stars, likes, dislikes]);

  const _star = () => {
    star(pnm.id, user.uid).then(res => {
      if (res) {
        setStars([...stars, user.uid]);
        setStarNames([...starNames, username]);
        _unlike();
        _undislike();
      }
    });
  };

  const _unstar = () => {
    setStars(stars.filter(s => s !== user.uid));
    setStarNames(starNames.filter(s => s !== username));
    unstar(pnm.id, user.uid);
  };

  const _like = () => {
    setLikes([...likes, user.uid]);
    setLikeNames([...likeNames, username]);
    like(pnm.id, user.uid);
    _unstar();
    _undislike();
  };

  const _unlike = () => {
    setLikes(likes.filter(l => l !== user.uid));
    setLikeNames(likeNames.filter(l => l !== username));
    unlike(pnm.id, user.uid);
  };

  const _dislike = () => {
    setDislikes([...dislikes, user.uid]);
    setDislikeNames([...dislikeNames, username]);
    dislike(pnm.id, user.uid);
    _unstar();
    _unlike();
  };

  const _undislike = () => {
    setDislikes(dislikes.filter(d => d !== user.uid));
    setDislikeNames(dislikeNames.filter(d => d !== username));
    undislike(pnm.id, user.uid);
  };

  const submitComment = () => {
    if (comment.length > 0) {
      addComment(pnm.id, comment, username, user.uid).then(id => {
        setComments([...comments, { id: id, name: username, comment: comment, commenterId: user.uid }]);
        setComment("");
      });
    }
  };

  const removeComment = (id) => {
    deleteComment(pnm.id, id).then(() => setComments(comments.filter(c => c.id !== id)));
  };

  const removePNM = () => {
    adminDeletePNM(pnm.id).then(() => {
      deletePicture(`pnms/${pnm.photoId}`);
      closeModal();
    });
  };

  return (
    <>
      <div className="absolute top-0 left-0 w-screen h-screen bg-black opacity-30 z-0" />
      <div className="w-3/4 h-3/4 bg-white rounded-md z-20 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="w-full h-full flex">
          <div className="w-2/3 h-full p-4">
            <HiXMark className="scale-150 hover:cursor-pointer absolute top-4 left-4" onClick={closeModal} />
            <div className="h-1/4 flex space-x-24 justify-center items-center">
              {stars.includes(user.uid) ? (
                <div className="text-cardinal-red" onMouseOver={() => setStarHidden(false)} onMouseOut={() => setStarHidden(true)}>
                  {!starHidden && 
                    <div className="flex justify-center">
                      <NamePopOver names={starNames} />
                    </div>
                  }
                  <FaStar className="w-16 h-16 hover:cursor-pointer mb-2" onClick={() => _unstar()} />
                  <p className="text-center text-2xl">{stars.length}</p>
                </div>
              ) : (
                <div className="text-hunter-green" onMouseOver={() => setStarHidden(false)} onMouseOut={() => setStarHidden(true)}>
                  {!starHidden && 
                    <div className="flex justify-center">
                      <NamePopOver names={starNames} />
                    </div>
                  }
                  <FaRegStar className="w-16 h-16 hover:cursor-pointer mb-2" onClick={() => _star()} />
                  <p className="text-center text-2xl">{stars.length}</p>
                </div>
              )}
              {likes.includes(user.uid) ? (
                <div className="text-cardinal-red" onMouseOver={() => setLikeHidden(false)} onMouseOut={() => setLikeHidden(true)}>
                  {!likeHidden && 
                    <div className="flex justify-center">
                      <NamePopOver names={likeNames} />
                    </div>
                  }
                  <FaThumbsUp className="w-16 h-16 hover:cursor-pointer mb-2" onClick={() => _unlike()} />
                  <p className="text-center text-2xl">{likes.length}</p>
                </div>
              ) : (
                <div className="text-hunter-green" onMouseOver={() => setLikeHidden(false)} onMouseOut={() => setLikeHidden(true)}>
                  {!likeHidden && 
                    <div className="flex justify-center">
                      <NamePopOver names={likeNames} />
                    </div>
                  }
                  <FaRegThumbsUp className="w-16 h-16 hover:cursor-pointer mb-2" onClick={() => _like()} />
                  <p className="text-center text-2xl">{likes.length}</p>
                </div>
              )}
              {dislikes.includes(user.uid) ? (
                <div className="text-cardinal-red" onMouseOver={() => setDislikeHidden(false)} onMouseOut={() => setDislikeHidden(true)}>
                  {!dislikeHidden && 
                    <div className="flex justify-center">
                      <NamePopOver names={dislikeNames} />
                    </div>
                  }
                  <FaThumbsDown className="w-16 h-16 hover:cursor-pointer mb-2" onClick={() => _undislike()} />
                  <p className="text-center text-2xl">{dislikes.length}</p>
                </div>
              ) : (
                <div className="text-hunter-green" onMouseOver={() => setDislikeHidden(false)} onMouseOut={() => setDislikeHidden(true)}>
                 {!dislikeHidden && 
                    <div className="flex justify-center">
                      <NamePopOver names={dislikeNames} />
                    </div>
                  }
                  <FaRegThumbsDown className="w-16 h-16 hover:cursor-pointer mb-2" onClick={() => _dislike()} />
                  <p className="text-center text-2xl">{dislikes.length}</p>
                </div>
              )}
            </div>
            <p className="font-bold text-hunter-green mb-2">Comments</p>
            <div className="h-3/5 overflow-y-auto space-y-2">
              {comments.map(c => {
                return <Comment key={c.id} c={c} deleteComment={() => removeComment(c.id)} />
              })}
            </div>
            <div className="w-2/3 flex space-x-2 items-center absolute bottom-2">
                <textarea className="w-4/5" value={comment} onChange={e => setComment(e.target.value)} />
                <div
                  className="bg-cardinal-red/[0.6] text-white text-center w-28 h-1/2 p-2 rounded-md hover:cursor-pointer hover:bg-cardinal-red/[0.8]"
                  onClick={submitComment}
                >
                  Comment
                </div>
            </div>
          </div>
          <div className="w-1/3 h-full">
            <img src={pnm.photo} alt={pnm.name} className="object-cover object-center h-3/4" />
            <div className="h-1/4 py-4">
              <p className="font-bold text-2xl">{pnm.name}</p>
              <p className="text-gray-500 text-lg">{pnm.email} • {pnm.phone}</p>
              <p className="text-gray-500 text-lg">{pnm.year} • {pnm.dorm} • {pnm.room}</p>
              {admin && <Button text="delete" onHit={removePNM} width="w-1/4" />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActionModal;