import React from "react";

const SearchInput = ({ items, setItems, filter, setFilter }) => {
  return (
    <input
      type="text"
      value={filter}
      onChange={e => {
        setFilter(e.target.value);
        setItems(items.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase())));
      }}
      className="w-4/5 p-2 rounded-md"
      placeholder="search PNM"
    />
  );
};

export default SearchInput;