import React, { useEffect, useState } from "react";
import { FadeLoader } from "react-spinners";
import { getPNMs } from "../api/pnm";
import PNMCard from "../components/PNMCard";
import ActionModal from "../components/ActionModal";
import Nav from "../components/Nav";
import SearchInput from "../components/SearchInput";

const Gallery = () => {
  const [loading, setLoading] = useState(false);
  const [pnms, setPNMs] = useState([]);
  const [filter, setFilter] = useState("");
  const [filteredPNMs, setFilteredPNMs] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [focusPNM, setFocusPNM] = useState({});

  useEffect(() => {
    setLoading(true);
    const unsubscribe = getPNMs(setPNMs);
    setLoading(false);

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    setFilteredPNMs(pnms.filter(pnm => pnm.name.toLowerCase().includes(filter.toLowerCase())));
  // eslint-disable-next-line
  }, [pnms]);

  const openModal = (pnm) => {
    setFocusPNM(pnm);
    setModalOpen(true);
  };

  const sortPNMs = (a, b) => {
    return (5 * b.stars.length + b.likes.length) - (5 * a.stars.length + a.likes.length);
  }

  return (
    <div className="h-screen w-screen bg-gray-200 pt-20 overflow-y-scroll">
      <Nav />
      <div className="w-full flex justify-center items-center">
        <SearchInput items={pnms} setItems={setFilteredPNMs} filter={filter} setFilter={setFilter} />
      </div>
      {modalOpen && <ActionModal pnm={focusPNM} closeModal={() => { setModalOpen(false); setFocusPNM({}); }} />}
      {loading ? (
        <FadeLoader color="white" />
      ) : ( 
        <div className="w-full p-8 grid grid-cols-2 md:grid-cols-4 gap-8">
          {filteredPNMs.sort(sortPNMs).map(pnm => {
            return <PNMCard key={pnm.id} pnm={pnm} setModalOpen={openModal} />
          })}
        </div>
      )}
    </div>
  );
};

export default Gallery;