import React, { useEffect, useState } from "react";
import { HiXMark } from "react-icons/hi2";
import { useAuth } from "../contexts/AuthContext";
import { fetchStarredPNMs, fetchLikedPNMs, fetchDislikedPNMs } from "../api/actives";

const ActiveStats = ({ onClose }) => {
  const { user, username } = useAuth();
  const [starNames, setStarNames] = useState([]);
  const [likeNames, setLikeNames] = useState([]);
  const [dislikeNames, setDislikeNames] = useState([]);

  useEffect(() => {
    if (!!user) {
      fetchStarredPNMs(user.uid).then(n => setStarNames(n));
      fetchLikedPNMs(user.uid).then(n => setLikeNames(n));
      fetchDislikedPNMs(user.uid).then(n => setDislikeNames(n));
    }
  // eslint-disable-next-line
  }, [user]);

  return (
    <>
      <div className="absolute z-40 top-0 left-0 w-screen h-screen bg-black opacity-20" />
      <div className="absolute z-50 top-0 h-screen right-0 p-4 bg-white w-1/4 overflow-y-scroll">
        <div className="flex w-full items-center mb-4">
          <div className="flex w-1/2">
            <HiXMark className="scale-150 hover:cursor-pointer hover:text-cardinal-red" onClick={() => onClose()} />
          </div>
          <div className="flex w-1/2 justify-end">
            <p className="font-semibold text-black text-xl">{username}'s Stats</p>
          </div>
        </div>
        <div className="space-y-6 overflow-y-auto">
          <div>
            <p className="font-semibold text-lg">Stars</p>
            {starNames.map((n, index) => {
              return <p key={n}>{index + 1}. {n}</p>
            })}
          </div>
          <div>
            <p className="font-semibold text-lg">Likes</p>
            {likeNames.map((n, index) => {
              return <p key={n}>{index + 1}. {n}</p>
            })}
          </div>
          <div>
            <p className="font-semibold text-lg">Dislikes</p>
            {dislikeNames.map((n, index) => {
              return <p key={n}>{index + 1}. {n}</p>
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveStats;