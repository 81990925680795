import React from "react";
import { FaRegThumbsUp, FaRegThumbsDown, FaRegStar } from "react-icons/fa";

const PNMCard = ({ pnm, setModalOpen }) => {
  return (
    <div className="w-64 max-h-72 pb-6 rounded-md bg-hunter-green/[0.8] hover:cursor-pointer" onClick={() => setModalOpen(pnm)}>
      <img className="h-2/3 w-full rounded-t-md" src={pnm.photo} alt={pnm.name} />
      <div className="h-1/3 w-full pt-2 px-6 text-white space-y-4">
        <p className="text-xl font-semibold">{pnm.name}</p>
        <div className="flex space-x-8 justify-center items-center">
          <div className="flex text-white space-x-2 items-center">
            <FaRegStar className="scale-150" />
            <p>{pnm.stars.length}</p>
          </div>
          <div className="flex text-white space-x-2 items-center">
            <FaRegThumbsUp className="scale-150" />
            <p>{pnm.likes.length}</p>
          </div>
          <div className="flex text-white space-x-2 items-center">
            <FaRegThumbsDown className="scale-150" />
            <p>{pnm.dislikes.length}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PNMCard;