import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { storage } from "./main";
import { uuidv4 } from "@firebase/util";

export const uploadPicture = async (file, name) => {
  const id = uuidv4();
  const storageRef = ref(storage, `pnms/${id}`);
  const metadata = {
    name: `${name.toLowerCase().split(" ").join("-")}-headshot`
  };

  try {
    const snapshot = await uploadBytes(storageRef, file, metadata);
    const url = await getDownloadURL(snapshot.ref);
    return { url: url, id: id };
  } catch (e) {
    console.error(e);
  }
};

export const deletePicture = async (fileRef) => {
  try {
    const storageRef = ref(storage, fileRef);
    await deleteObject(storageRef);
  } catch (e) {
    console.error(e);
  }
};