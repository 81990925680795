import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../api/main";
import { useAuth } from "../contexts/AuthContext";
import ActiveStats from "../components/ActiveStats";
import Button from "./Button";

const Nav = () => {
  const navigate = useNavigate();
  const { setUser, setUsername } = useAuth();
  const [statsOpen, setStatsOpen] = useState(false);

  return (
    <div className="fixed w-full h-16 flex items-center top-0 bg-white border border-black/[0.5] z-40">
      {statsOpen && <ActiveStats onClose={() => setStatsOpen(false)} />}
      <div className="w-1/2 flex justify-start px-4">
        <img src="phi-psi-emblem.png" className="w-12" alt="Phi Psi Emblem" />
      </div>
      <div className="w-1/2 flex justify-end px-4 space-x-4">
        <Button text="pnm sign in" onHit={() => navigate("/pnm")} />
        <Button text="statistics" onHit={() => setStatsOpen(true)} />
        <Button
          text="sign out"
          onHit={() => {
            logout().then(() => {
              setUser(null);
              setUsername("");
              navigate("/login");
            })
          }}
        />
      </div>
    </div>
  );
};

export default Nav;