import { db } from "./main";
import { collection, addDoc, getDocs, query, doc, updateDoc, arrayUnion, arrayRemove, deleteDoc, where, onSnapshot } from "firebase/firestore";

const yearConversion = {
  0: "freshman",
  1: "sophomore",
  2: "other"
};

export const addPNM = async (name, email, phone, year, photo, photoId, dorm, room) => {
  try {
    await addDoc(collection(db, "pnms"), {
      name: name,
      email: email,
      phone: phone,
      year: yearConversion[year],
      photo: photo,
      photoId: photoId,
      dorm: dorm,
      room: room,
      likes: [],
      dislikes: [],
      stars: [],
    });
  } catch (e) {
    console.error(e);
  }
};

export const getPNMs = (setPNMs) => {
  try {
    // const pnms =  getDocs(query(collection(db, "pnms")));
    // const data = pnms.docs.map(doc => { return { id: doc.id, ...doc.data() } });
    // return data;

    return onSnapshot(query(collection(db, "pnms")), snapshot => {
      const data = snapshot.docs.map(doc => { return { id: doc.id, ...doc.data() }});
      setPNMs(data);
    });
  } catch (e) {
    console.error(e);
  }
};

export const addComment = async (pnmId, comment, commenterName, commenterId) => {
  try {
    const doc = await addDoc(collection(db, "pnms", pnmId, "comments"), {
      name: commenterName,
      comment: comment,
      commenterId: commenterId
    });
    return doc.id;
  } catch (e) {
    console.error(e);
  }
};

export const deleteComment = async (pnmId, id) => {
  try {
    await deleteDoc(doc(db, "pnms", pnmId, "comments", id));
  } catch (e) {
    console.error(e);
  }
};

export const getComments = (pnmId, setComments) => {
  try {
    return onSnapshot(query(collection(db, "pnms", pnmId, "comments")), snapshot => {
      const docs = snapshot.docs.map(doc => { return { id: doc.id, ...doc.data() } });
      setComments(docs);
    });
  } catch (e) {
    console.error(e);
  }
};

export const star = async (pnmId, activeId) => {
  try {
    const pnmRef = doc(db, "pnms", pnmId);
    // ensure active has no already used 5 stars
    const snapshot =  await getDocs(query(collection(db, "pnms"), where("stars", "array-contains", activeId)));
    const starredPNMs = snapshot.docs;
    if (starredPNMs.length === 1) return false;

    await updateDoc(pnmRef, { stars: arrayUnion(activeId) });
    return true;
  } catch (e) {
    console.error(e);
  }
};

export const unstar = async (pnmId, activeId) => {
  try {
    const pnmRef = doc(db, "pnms", pnmId);
    await updateDoc(pnmRef, { stars: arrayRemove(activeId) });
  } catch (e) {
    console.error(e);
  }
};

export const like = async (pnmId, activeId) => {
  try {
    const pnmRef = doc(db, "pnms", pnmId);
    await updateDoc(pnmRef, { likes: arrayUnion(activeId) });
  } catch (e) {
    console.error(e);
  }
};

export const unlike = async (pnmId, activeId) => {
  try {
    const pnmRef = doc(db, "pnms", pnmId);
    await updateDoc(pnmRef, { likes: arrayRemove(activeId) });
  } catch (e) {
    console.error(e);
  }
};

export const dislike = async (pnmId, activeId) => {
  try {
    const pnmRef = doc(db, "pnms", pnmId);
    await updateDoc(pnmRef, { dislikes: arrayUnion(activeId) });
  } catch (e) {
    console.error(e);
  }
};

export const undislike = async (pnmId, activeId) => {
  try {
    const pnmRef = doc(db, "pnms", pnmId);
    await updateDoc(pnmRef, { dislikes: arrayRemove(activeId) });
  } catch (e) {
    console.error(e);
  }
};

export const exportPNMs = async () => {
  try {
    const res = [];
    const pnms = await getDocs(query(collection(db, "pnms")));
    pnms.forEach(pnm => {
      const data = pnm.data();
      res.push(data.name);
    });
  } catch (e) {
    console.error(e);
  }
};

export const adminDeletePNM = async (pnmId) => {
  try {
    const comments = await getDocs(query(collection(db, "pnms", pnmId, "comments")));
    comments.forEach(async c => {
      await deleteDoc(c.ref);
    });
    await deleteDoc(doc(db, "pnms", pnmId));
  } catch (e) {
    console.error(e);
  }
};