import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getFirestore, addDoc, collection } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export const createUser = async (email, name, pw) => {
  try {
    const cred = await createUserWithEmailAndPassword(auth, email, pw);
    await addDoc(collection(db, "actives"), {
      uid: cred.user.uid,
      name: name
    });
    return cred.user;
  } catch(e) {
    return null;
  }
};

export const login = async (email, pw) => {
  try {
    const cred = await signInWithEmailAndPassword(auth, email, pw);
    return cred.user;
  } catch(e) {
    return null;
  }
};

export const logout = async () => {
  try {
    await signOut(auth);
  } catch (e) {
    console.error(e);
  }
};