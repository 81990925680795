import React from "react";

const Button = ({ text, width = "", onHit }) => {
  return (
    <div
      className={`${width} bg-cardinal-red/[0.5] hover:bg-cardinal-red/[0.7] hover:cursor-pointer text-white font-semibold p-2 rounded-md text-sm text-center`}
      onClick={onHit}
    >
      {text}
    </div>
  );
};

export default Button;