import React from "react";
import { HiXMark } from "react-icons/hi2";
import { useAuth } from "../contexts/AuthContext";

const Comment = ({ c, deleteComment}) => {
  const { user } = useAuth();
  return (
    <div className="w-full flex">
      <div className="w-4/5">
        <p className="font-bold">{c.name}</p>
        <p className="text-gray-500 text-sm">{c.comment}</p>
      </div>
      {user.uid === c.commenterId && (
        <div className="w-1/5 flex justify-end">
          <HiXMark
            className="scale-125 hover:cursor-pointer hover:text-cardinal-red"
            onClick={deleteComment}
          />
        </div>
      )}
    </div>
  );
};

export default Comment;