import React from "react";

const PhoneOverlay = () => {
  return (
    <div className="w-screen h-screen bg-hunter-green flex flex-wrap justify-center items-center px-8 text-center">
      <img src="phi-psi-emblem.png" className="w-3/4" alt="Phi Kappa Psi Emblem" />
      <p className="font-semibold text-white w-full">Mobile compatibility under construction. Please use a desktop.</p>
    </div>
  );
};

export default PhoneOverlay;