import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../api/main";
import Button from "../components/Button";
import { useAuth } from "../contexts/AuthContext";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (!!user) navigate("/");
  // eslint-disable-next-line
  }, [user]);

  const checkValid = () => {
    return email.length > 0 && password.length >= 0;
  };

  const handleLogin = () => {
    if (checkValid()) login(email, password).then(res => {
      if (!!res) navigate("/")
      else setError(true);
    });
    else setError(true);
  };

  const handleEnter = e => {
    if (e.key === "Enter") handleLogin();
  };

  return (
    <div className="h-screen w-screen bg-hunter-green/[0.8] flex justify-center items-center">
      <div className="space-y-6 w-1/4">
        <p className="font-bold text-5xl text-white">Login</p>
        <div>
          <p className="font-semibold text-white">Email</p>
          <input type="text" value={email} onChange={e => setEmail(e.target.value)} className="w-full rounded-md" />
        </div>
        <div>
          <p className="font-semibold text-white">Password</p>
          <input type="password" value={password} onChange={e => setPassword(e.target.value)} className="w-full rounded-md" onKeyDown={handleEnter} />
        </div>
        <div className="flex justify-center items-center">
          <Button
            text="login"
            width="w-1/4"
            onHit={() => handleLogin()}
          />
        </div>
        {error && <p className="text-cardinal-red/[0.7] font-bold">Missing or incorrect credentials.</p>}
      </div>
    </div>
  );
};

export default Login;